import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { get } from 'lodash'
import { IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

const Row = styled.div`
    display: flex;
    align-items: center;
`

const MiniIcon = ({icon, title, ...rest}) => {
  return <Tooltip title={title}>
      <IconButton style={{padding: '6px'}} {...rest}>
          {React.createElement(icon, {fontSize: 'small'})}
      </IconButton>
  </Tooltip>
}

const FormArray = ({ 
  name='', 
  defaultValue={},
  onInsert=(fields, index, insert) => insert(index+1, defaultValue),
  onDelete=(fields, index, remove) => remove(index),
  render 
}) => {
  const { control, formState: {errors} } = useFormContext()
  const { fields, remove, insert } = useFieldArray({
        name, control
  })

  const err = get(errors, name)?.message

  return (
    <div>
        {fields.map((field, index) => <Row container key={field.id}>
            <MiniIcon icon={AddIcon} title='Add' onClick={() => onInsert(fields, index, insert)} />
            {render({fields, field, index, name})}
            <MiniIcon icon={RemoveIcon} title='Remove' onClick={() => onDelete(fields, index, remove)} />
        </Row>)}
        {err && <div style={{color: 'red'}}>{err}</div>}
    </div> 
  )
}

export default FormArray;